.App {
  text-align: center;
  color: black;
}

textarea {
  height: 100px !important;
}

.error {
  font-size: smaller;
  background-color: rgb(253, 107, 107);
  width: 100%;
}

#missing-fields {
  text-align: left;
}

form button {
  height: 34px;
}
.report-header {
  text-align: center;
  margin-top: -100px;
  padding-bottom: 100px;
}

.form-group {
  font-size: 0.8em;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: -40px;
}

.cal-input {
  cursor: pointer;
  max-width: 25vw;
  font-size: "12px";
  align-items: center;
  justify-content: center;
  margin-bottom: -30px;
}

.col-md-8 .form-group {
  border: 1px solid grey;
  padding: 10px;
  border-radius: 5px;
  width: fit-content;
}

.action-panel {
  padding: 15px;
  border-radius: 5px;
  background-color: whitesmoke;
  width: min-content;
  text-align: center;
}
.sticky {
  position: sticky;
  top: 55%;
  right: 15%;
}
.invalid-json {
  font-size: 0.8em;
  color: red;
}
.content {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #282c34;
  font-size: 18px;
  color: black !important;
}

@media only screen and (min-width: 600px) {
  .container {
    position: relative;
  }
  .container .vertical-line {
    border-right: 1px solid grey;
    height: 200vh;
    position: absolute;
    right: 4%;
    top: 10%;
  }
}

#growth {
  width: 95vw;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  #growth {
    text-align: center;
    width: 90vw;
  }
  .cal-input {
    cursor: pointer;
    max-width: 10vw;
    font-size: "10px";
    background-color: #282c34 !important;
    border-color: #282c34 !important;
    color: white !important;
  }
}

.logo {
  width: 100px;
  margin-top: -10px;
  object-position: top left;
  object-fit: cover;
  filter: brightness(0) invert(1);
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.card {
  width: 60vw !important;
}

.card-image {
  align-self: flex-start !important;
  margin: 10px;
  width: 200px;
  border-radius: 10px !important;
}

.card-header {
  align-self: flex-end !important;
}

#header {
  color: rgb(37, 36, 36) !important;
}

.cards-list {
  width: 60vw !important;
  margin-top: 40px;
}

.card-meta {
  align-self: flex-end !important;
  font-size: smaller;
  padding-bottom: 60px;
}

.cards-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 600px) {
  .cards-row {
    display: block;
  }

  .card-meta {
    align-self: flex-end !important;
    font-size: smaller;
    padding-bottom: 0px;
  }

  .card {
    width: 120vw !important;
  }

  .cards-list {
    width: 120vw !important;
    margin-top: 40px;
  }

  .pagination_bar {
    text-align: center !important;
    align-items: center !important;
  }

  element.style {
    position: absolute;
    z-index: 999;
    pointer-events: auto;
    top: 135.99px;
    left: 329.969px;
  }
}

.card-description {
  font-size: smaller;
}

[role="tooltip"].popup-content {
  width: 450px !important;
  padding-left: 15px;
  border-radius: 10px !important;
}

.custom-group {
  padding-left: 20px;
  padding-top: 10px;
}

#websiteInput,
.multi-select {
  width: 400px;
  margin-left: 7.5px;
}

.popup-modal {
  /* margin-top: -100px; */
  font-size: 16px;
}
#popup-1 {
  margin-top: 50px !important;
}
.popup-modal-header {

  color: whitesmoke;
  background-color: #282c34;
  width: 100%;
  font-size: 28px!important;
  text-align: center!important;
  padding-top: 20px;
}

.popup-modal-content {
  color: whitesmoke;
  background-color: #282c34;
  width: 100%;
  padding: 10px 5px;
}
.popup-modal-actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.popup-modal-close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  opacity: unset;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.process-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 5px;
}

.process {
  display: block;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background: white;
}
.process-text {
  font-size: 1em;
  margin-top: 60px;
  text-align: center !important;
  width: 80px;
}
.process-line-before {
  margin-top: 40px;
  margin-left: 0px;
  width: 80px !important;
  height: 80px;
}

.process-line-after {
  margin-top: 40px;
  margin-left: 0px;
  width: 80px !important;
  height: 80px;

}

.process-line-end {
  margin-top: 40px;
  margin-left: 0px;
  width: 40px;
  height: 40px;
}

.process-icon {
  color: black;
  position: relative;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  transform: translate(-50%, -50%);
}

.popup-modal-content > .results {
  color: black;
  width: 100%;
  height: 200px!important;
}

.login-container {
  min-width: 450px !important;
  padding: 20px 20px;
}

.login-form {
  font-size: 14px;
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 15px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.field {
  color: black;
  font-size: 14px;
  text-align: left;
  padding-top: 10px;
}
.remember-me {
  color: black;
  font-size: 14px;
  text-align: right;
  padding-bottom: 10px;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.exit-button {
  color: #e0dfdf;
  position: absolute;
  top: 20px;
  right: 5vw;
}

.exit-button:hover {
  color: #fdfdfc;
  position: absolute;
}
